import './../css/app.pcss';
import { createApp } from 'vue';
import CustomerStore from '/src/vue/stores/customer.ts';

// Commons
import header from '/src/ts/commons/header.ts';
import footer from '/src/ts/commons/footer.ts';
import csrf from '/src/ts/commons/csrf.ts';

// Modules
import swiper from '/src/ts/modules/swiper.ts';

// Components
import SwiperHero from '/src/vue/components/SwiperHero.ts';

// Entry point logic
export default function init() {
  csrf();
  // Create our store
  const store = CustomerStore();
  // Header Vue Instance
  header(store);
  // Main Vue instance
  const vueInstance = createApp({
    delimiters: ['${', '}'],
    components: {
      SwiperHero,
    },
  });
  // Use the store
  vueInstance.use(store);
  // Mount the app
  vueInstance.mount('#homepage');
  // Modules
  swiper();
  // Footer Vue Instance
  footer();
}
init();
