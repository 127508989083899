import { defineComponent } from 'vue';
// Import Swiper Vue.js components
import { Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default defineComponent({
    delimiters: ['${', '}'],
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        headline: {
            type: String,
            default: null,
        },
        copy: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        isSos: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            EffectFade,
            Autoplay,
            Pagination
        };
    },
    template: '#t-swiper-hero',
});
